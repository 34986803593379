<template>
  <div class="content">
    <div class="row" style="background: linear-gradient(#14191c, #1a1a1a)">
      <div class="text-center col-xl-8 col-sm-12">
        <HeadImage
          :image="cover"
          title="Los Cabos | Concierge Services"
          caption="Your VIP Experience"
          :color="secondaryColor"
        ></HeadImage>

        <div style="padding: 10px" class="text-justify">
          <p>
            <strong
              >Welcome to the ultimate Concierge Service for party
              lovers in Los Cabos!</strong
            >
          </p>
          <p>
            Whether you're looking to secure a VIP table at the
            <strong
              >hottest clubs, dine at exclusive restaurants, or get your hands
              on tickets for electrifying festivals</strong
            >
            featuring techno, house, and reggaeton beats, we've got you covered.
          </p>
          <p>
            Our concierge team is here to elevate your experience, ensuring you
            have access to the best venues and events in town.
            <strong>Let us handle the details</strong>, so you can focus on
            enjoying the music and making memories that last a lifetime.
          </p>
        </div>
        <br />

        <div style="margin-top: 35px">
          <h3 class="text-left section-header">
            <i class="tim-icons icon-book-bookmark"></i>&nbsp; Our
            Recommendations
          </h3>
          <img-card
            title="Taboo Los Cabos"
            sub-title="Lively Latin music"
            align="center"
            width="200"
            height="200"
            image="https://imgix.cosmicjs.com/dc100b10-f774-11ee-b555-0d0678c27dd7-taboo-los-cabos-6.jpg"
          ></img-card>
        </div>
        <div class="card" style="padding: 3px">
          <h3>Clubs, Dining, and Events</h3>
          <ul class="text-left">
            <li>
              <strong>
                <router-link :to="{ path: '/los-cabos-clubs/taboo-los-cabos' }"
                  >Taboo
                </router-link> </strong
              >An exclusive destination combining exquisite gourmet dining with
              lively Latin music.
            </li>
            <li>
              <strong>
                <router-link
                  :to="{ path: '/los-cabos-clubs/bagatelle-los-cabos' }"
                  >Bagatelle
                </router-link>
              </strong>
              Is where you want to be for a luxury time out.
            </li>
            <li>
              <strong>
                <router-link
                  :to="{ path: '/los-cabos-clubs/rosa-negra-los-cabos' }"
                  class=""
                  >Rosa Negra
                </router-link>
              </strong>
              Savor a menu inspired by the high-quality flavors of Latin America
              and classic steakhouse fare.
            </li>
            <li>
              <strong>
                <router-link :to="{ path: '/los-cabos-clubs/crania-los-cabos' }"
                  >Crania
                </router-link>
              </strong>
              An innovative restaurant & air disco nightclub that merges
              tradition and rebellion.
            </li>
            <li>
              <strong>
                <router-link
                  :to="{ path: '/los-cabos-clubs/chambao-los-cabos' }"
                  >Chambao
                </router-link>
              </strong>
              Witness a mesmerizing fusion of fire, water, and culinary artistry
              as you savor every bite.
            </li>
          </ul>
          <br />
          <div class="centered">
            <MiniCarrousel
              title="Discover the heartbeat of Los Cabos nightlife!"
              folder="concierge-services-media"
              num-photos="4"
            ></MiniCarrousel>
          </div>
        </div>
        <card
          class="card text-center col-md-12 col-md-10 offset-md-1"
          style="z-index: 1; margin: 2px"
        >
          <img
            v-lazy="serviceIcon + '?w=80&h=80'"
            height="80"
            width="80"
            alt="contact us"
            class="centered"
          />

          <hr />
          <div
            class="card"
            style="padding: 20px"
            :style="{
              background: `linear-gradient(${this.secondaryColor}, black)`,
            }"
          >
            <br />
            <p>
              Trust our <strong> experience</strong> and make
              <strong>your experience unforgettable</strong>
            </p>
            <br />
          </div>

          <div class="card text-left" style="padding: 20px">
            <ul>
              <li>
                <strong>Effortless Reservations</strong>: Secure your place at
                the most popular venues with our reliable booking service.
              </li>
              <li>
                <strong>Personalized Suggestions</strong>: We'll guide you to
                the ideal clubs and events based on your unique preferences.
              </li>
              <li>
                <strong>Comprehensive Support</strong>: From the beggining to
                the end, we're here to answer any questions.
              </li>
              <li>
                <strong>Constant Updates</strong>: We stay in touch to make sure
                you have all the necessary details and information you need.
              </li>
              <li>
                <strong>Event Planning</strong>: Let us handle the arrangements
                for your special occasion.
              </li>
              <li>
                <strong>Your Special Occasion</strong>: No matter what event
                you're planning, we'll make your occasion truly memorable and
                special.
              </li>
            </ul>
          </div>
          <SmartMenu :links="links" :logo="dj" :primary-color="clubColor">
            <div
              class="col-sm-12 text-center"
              style="padding: 10px; margin: 10px"
            >
              <p>
                Relax, take a deep breath, and trust us to handle every detail
                <br /><strong>Your peace of mind is our priority!</strong>
              </p>
            </div>
          </SmartMenu>
          <br />
        </card>
        <br />
      </div>

      <aside class="card col-4 col-xl-4 d-none d-xl-inline">
        <section
          :style="{ background: `linear-gradient(${this.clubColor}, black )` }"
        >
          <h4 class="section-header">
            <i class="tim-icons icon-trophy"></i>
            BENEFITS
          </h4>

          <ul style="font-weight: normal">
            <li>Skip the Planning, Focus on Fun</li>
            <li>Expert Local Knowledge</li>
            <li>Stress-Free Celebration</li>
            <li>Top Clubs</li>
            <li><strong>NO EXTRA FEE</strong></li>
          </ul>
          <br />
        </section>
        <SmartMenu
          :links="this.links"
          :logo="this.dj"
          :primary-color="clubColor"
        >
          <div class="row">
            <div class="col-sm-12 text-center">
              <p>
                Relax, take a deep breath, and trust us to handle every detail
                <br /><strong>Your peace of mind is our priority!</strong>
              </p>
            </div>
          </div>
        </SmartMenu>
      </aside>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImgCard from '@/components/Cards/ImgCard';
import MiniCarrousel from '@/components/MiniCarrousel';
import HeadImage from '@/components/HeadImage/HeadImage';
import SmartMenu from '@/components/SmartMenu/SmartMenu';

export default {
  name: 'ConciergeServices',
  props: ['slug'],
  metaInfo() {
    return {
      title: `Los Cabos | Concierge Services. Party Experts Concierge for Clubs, Festivals & More`,
      meta: [
        {
          name: 'description',
          content:
            "Planning a bachelor blowout, festival frenzy, or jungle escapade? We've got you covered! From beachside vibes to gourmet dining, we ensure unforgettable experiences while you relax. Let us handle the details.",
        },
        {
          name: 'keywords',
          content: `Los Cabos music festivals, electronic music Los Cabos, Los Cabos nightclubs, techno festivals Los Cabos, house music Los Cabos, Los Cabos nightlife, Los Cabos club events, DJ events Los Cabos, Los Cabos party scene, reggaeton festivals Los Cabos, EDM festivals Mexico, Los Cabos club reservations, VIP table reservations Los Cabos, beach parties Los Cabos, Los Cabos music events, nightlife in Los Cabos, club promotions Los Cabos, Los Cabos festival tickets, electronic dance music Los Cabos, Los Cabos party planning, Los Cabos live music venues, Mexico electronic music scene, Los Cabos nightlife guide`,
        },
        {
          property: 'og:title',
          content: ` Los Cabos Concierge Services`,
        },
        {
          property: 'og:url',
          content: `https://cabo.party/los-cabos-concierge-services`,
        },
        {
          property: 'og:description',
          content: ` Los Cabos Concierge Services`,
        },
        {
          property: 'og:type',
          content: 'article',
        },
        {
          property: 'og:image',
          content: this.main_image,
        },
      ],
    };
  },
  data: () => {
    return {
      showAll: false,
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      dj: 'https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg',
      cover:
        'https://imgix.cosmicjs.com/64e3d2b0-649d-11ef-b5ae-a594bb4a8e67-concierge-cover.jpg',
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      clubColor: '#1b2124',
      secondaryColor: '#596373',
      showPalms: false,
      clubStyle: '',
      clubConfig: {},
      rsvpConfig: {
        name: 'Wedding Planners',
        logo: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',
      },
      clubTagsConfig: {},
      serviceIcon:
        'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',
      main_image:
        'https://imgix.cosmicjs.com/a14d2120-656a-11ef-b5ae-a594bb4a8e67-Dj-main.jpg?w=300&h=300&fit=clamp',
      links: [
        {
          slug: 'los-cabos-mexico-event-calendar-dinner-table-reservations',
          title: 'los-cabos-mexico-event-calendar-dinner-table-reservations',
          metadata: {
            link_to: '/los-cabos-dinner-table-reservations',
            image: {
              imgix_url:
                'https://imgix.cosmicjs.com/8368e7d0-52ea-11ef-b1ea-f56c65dfade9-6f62e280-1452-11ef-9eca-7d347081a9fb-salsa-dancin.jpg',
            },
            caption: 'Discover luxury dining at its finest in Los Cabos.\n',
            text: 'Dinner & Shows',
          },
        },
        {
          id: '66b060e7d70e5c5cd3fa674f',
          slug: 'los-cabos-mexico-event-calendar-taboo-los-cabos',
          title: 'los-cabos-mexico-event-calendar-taboo-los-cabos',
          metadata: {
            link_to: '/los-cabos-clubs/taboo-los-cabos',
            image: {
              imgix_url:
                'https://cdn.cosmicjs.com/2d937c80-52ea-11ef-b1ea-f56c65dfade9-bc3f0f60-f62b-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos.avif',
            },
            caption: 'Where exquisite dining meets electrifying vibes.',
            text: 'Taboo Los Cabos',
          },
        },
        {
          id: '661f0ae08eeb799dd185f24e',
          slug: 'los-cabos-mexico-event-calendar-bagatelle-dinner-experience',
          title: 'los-cabos-mexico-event-calendar-bagatelle-dinner-experience',
          metadata: {
            link_to: '/los-cabos-clubs/bagatelle-los-cabos',
            image: {
              imgix_url:
                'https://imgix.cosmicjs.com/38c29680-f62a-11ee-b555-0d0678c27dd7-BAGATELLE_LOS_CABOS___bagatelleloscabos.jpg',
            },
            caption:
              'A dining experience that combines food with top-notch entertainment ',
            text: 'Bagatelle. Dinner & Party ',
          },
        },
      ],
    };
  },
  methods: {},
  components: {
    SmartMenu,
    HeadImage,
    //Carrousel,
    ImgCard,
    MiniCarrousel,
  },
  computed: mapState({
    incomingClubParties: (state) => {
      return state.incomingClubParties[state.venue.slug] || [];
    },
    club: (state) => state.venue,
    clubs: (state) => state.venues,
  }),
  created() {},
};
</script>
